const syncOptions = {
    insurance: [
        {
            label: '最新通知資料',
            isDivider: true
        },
        {
            label: '照會',
            value: 'note',
            amount: 'N'
        },
        {
            label: '保服',
            value: 'insService',
            amount: 'N'
        },
        {
            label: '保單通知',
            value: 'insNotify',
            amount: 'N'
        },
        {
            label: '最新保單資料',
            isDivider: true
        },
        {
            label: '保單基本資料',
            value: 'mainContract',
            amount: 1 //回傳筆數:1,7,10...(number最近幾筆)or'N'(string多筆)
            //amountNote: 'string' //自訂回傳筆數的說明文字
            //note: ['string'] //Array裡加入這個檔案的所有備註
        },
        {
            label: '主/附約',
            value: 'subContract',
            amount: 1
        },
        {
            label: '最新保價',
            value: 'insValue',
            amount: 7
        },
        {
            label: '續期扣款通知',
            value: 'renewDeduction',
            amount: 'N'
        },
        {
            label: '保費紀錄',
            value: 'insFeeRecord',
            amount: 'N'
        },
        {
            label: '保障明細表',
            value: 'indemnityDetail',
            amount: 1
        },
        {
            label: '保單利益給付表',
            value: 'insuranceInterest',
            amount: 1,
            note: ['僅顯示本保單有利益給付表資訊的商品']
        },
        {
            label: '滿期金',
            value: 'maturityAmount',
            amount: 'N'
        },
        {
            label: '保單簽收',
            value: 'policyReceipt',
            amount: 'N'
        },
        {
            label: '首期送金單',
            value: 'firstFeeReceipt',
            amount: 1
        },
        {
            label: '基金',
            value: 'fund',
            amount: 'N'
        },
        {
            label: '最新客戶資料',
            isDivider: true
        },
        {
            label: '客戶',
            value: 'customerBySupplier',
            amount: 1,
            amountNote: '依客戶姓名排序，顯示每位客戶最近1次更新紀錄'
        }
    ],
    notification: [
        {
            label: '照會',
            value: 'note',
            amount: 'N'
        },
        {
            label: '保單通知',
            value: 'insNotify',
            amount: 'N'
        }
    ],
    customer: [
        {
            label: '客戶',
            value: 'customerBySupplier',
            amount: 1
        }
    ]
}

const config = {
    // 保單基本資料
    mainContract: [
        {
            key: 'proposalApplyDate',
            label: '要保書申請日'
            //note: ['key'] //如有要顯示備註，對應下方syncDataNote的forSome加入key
        },
        { key: 'underwriteDate', label: '核保日期' },
        { key: 'insDate', label: '保單生效日' },
        { key: 'insStatus', label: '保單狀態' },
        { key: 'itemStatusChgDate', label: '異動日', isTitle: true },
        { key: 'payType', label: '繳別' },
        { key: 'currencyType', label: '幣別' },
        { key: 'expiredPayDate', label: '保費最後應繳日' },
        { key: 'insuredId', label: '主被保人ID' },
        { key: 'toInsureId', label: '要保人ID' },
        { key: 'chargeAddr', label: '要保人收費地址', cell: 1 },
        { key: 'givenMethod', label: '滿期約定給付方式' },
        { key: 'branchCode', label: '分行代碼' },
        { key: 'salesCode', label: '保險業務行員代碼' },
        { key: 'salesName', label: '保險業務行員' },
        { key: 'fileDate', label: '保險公司製檔日' }
    ],
    // 附約
    subContract: [
        { key: 'productName', label: '商品名稱', cell: 1, isTitle: true },
        { key: 'insuredId', label: '主被保人ID' },
        { key: 'insuredAge', label: '被保人年齡' },
        { key: 'toInsuredRelation', label: '要被保人關係' },
        { key: 'contractStatus', label: '主約狀態' },
        { key: 'contractDate', label: '主約生效日' },
        { key: 'indemnityPeriod', label: '保障年期' },
        { key: 'indemnityExpireDate', label: '保障到期日' },
        { key: 'period', label: '繳費年期' },
        { key: 'insAmount', label: '保額' },
        { key: 'insFee', label: '保費', isMoney: true }, //isMoney金額需要加小數點後兩位數
        { key: 'insFeeTypeDesc', label: '保費類型' },
        { key: 'itemStatusChgDate', label: '異動日' },
        { key: 'fileDate', label: '保險公司製檔日' }
    ],
    // 保單簽收
    policyReceipt: [
        { key: 'branchName', label: '分行名稱' },
        { key: 'productCode', label: '商品代碼' },
        { key: 'insDate', label: '保單生效日' },
        { key: 'insIssueDate', label: '保單發單日' },
        { key: 'insMailingDate', label: '保單郵寄日' },
        { key: 'signedBackExp', label: '應簽回日期', isTitle: true },
        { key: 'signedBackDate', label: '客戶簽收日' },
        { key: 'signedBackReceive', label: '簽收回條收件日' },
        { key: 'fileDate', label: '保險公司製檔日' }
    ],
    // 首期送金單
    firstFeeReceipt: [
        { key: 'moneyGiveNo', label: '送金單號碼' },
        { key: 'paidFee', label: '實收保費', isMoney: true },
        { key: 'currencyType', label: '幣別' },
        { key: 'payType', label: '繳別' },
        { key: 'payMethod', label: '繳費方式' },
        { key: 'isCasualtyInsuranceText', label: '意外險' },
        { key: 'fileDate', label: '保險公司製檔日', isTitle: true }
    ],
    // 保單利益給付表
    insuranceInterest: [
        {
            key: 'productName',
            label: '商品名稱',
            cell: 1,
            isTitle: true
        },
        {
            key: 'proposalVersionNo',
            label: '要保書版號'
        },
        {
            key: 'currencyType',
            label: '幣別',
            isMoney: true
        },
        {
            key: 'itemStatusChgDate',
            label: '異動日'
        },
        { key: 'fileDate', label: '保險公司製檔日' }
    ],
    // 保障明細表
    indemnityDetail: [
        {
            key: 'productName',
            label: '商品名稱',
            cell: 1,
            isTitle: true
        },
        {
            key: 'unitWithAmount',
            label: '單位計畫'
        },
        {
            key: 'proposalVersionNo',
            label: '要保書版號'
        },
        {
            key: 'currencyType',
            label: '幣別'
        },
        {
            key: 'itemStatusChgDate',
            label: '保項狀態異動日'
        },
        { key: 'fileDate', label: '保險公司製檔日' },
        {
            key: 'insuredItems',
            label: '給付項目/保險金額',
            cell: 1,
            isWrap: true
        }
    ],
    // 最新保價
    insValue: [
        {
            key: 'accountValueOriginal',
            label: '帳戶價值-原幣',
            isMoney: true
        },
        {
            key: 'accountValueTW',
            label: '帳戶價值-台幣',
            isMoney: true
        },
        {
            key: 'cancelFeeTW',
            label: '解約金-台幣',
            isMoney: true
        },
        {
            key: 'calculateDate',
            label: '計算日期',
            isTitle: true
        },
        { key: 'fileDate', label: '保險公司製檔日' }
    ],
    // 滿期金
    maturityAmount: [
        {
            key: 'givenOri',
            label: '預計給付金額-原幣',
            isMoney: true
        },
        { key: 'givenDate', label: '預計給付日', isTitle: true },
        { key: 'givenType', label: '類別' },
        { key: 'givenFrequency', label: '給付頻率' },
        { key: 'fileDate', label: '保險公司製檔日' }
    ],
    // 續期扣款通知
    renewDeduction: [
        { key: 'payMethod', label: '滿期約定給付方式' },
        {
            key: 'totalPaid',
            label: '總繳保費',
            isMoney: true
        },
        { key: 'nextPayDate', label: '下次繳費日', isTitle: true },
        { key: 'fileDate', label: '保險公司製檔日' }
    ],
    // 保費紀錄
    insFeeRecord: [
        { key: 'productName', label: '商品名稱', cell: 1 },
        { key: 'payType', label: '繳別' },
        { key: 'shouldPayDate', label: '應繳日' },
        { key: 'paymentYear', label: '繳費年度' },
        { key: 'paymentCount', label: '繳費次數' },
        { key: 'currencyType', label: '保單幣別' },
        {
            key: 'totalPaid',
            label: '總繳保費',
            isMoney: true
        },
        { key: 'creditDate', label: '入帳日期', isTitle: true },
        { key: 'insFeeTypeDesc', label: '保費類型' },
        { key: 'fileDate', label: '保險公司製檔日' }
    ],
    // 照會
    note: [
        { key: 'branch', label: '分行代號' },
        { key: 'toInsureId', label: '要保人ID' },
        { key: 'insuredId', label: '被保人ID' },
        { key: 'noteDate', label: '照會日期', isTitle: true },
        { key: 'noteExpireDate', label: '照會截止日期' },
        { key: 'noteReplyDate', label: '照會回覆日' },
        { key: 'eNoteText', label: '是否e照會' },
        { key: 'noteDetailType', label: '照會類型', cell: 1 },
        { key: 'noteContent', label: '照會內容', cell: 1 },
        { key: 'fileDate', label: '保險公司製檔日' }
    ],
    //保服
    insService: [
        { key: 'serviceEvent', label: '保服項目' },
        { key: 'serviceContent', label: '保服內容' },
        { key: 'toInsureName', label: '要保人' },
        { key: 'changeApplyDate', label: '契變申請日' },
        { key: 'changeDate', label: '契變生效日', isTitle: true },
        { key: 'fileDate', label: '保險公司製檔日' }
    ],
    // 保單通知
    insNotify: [
        { key: 'currency', label: '保單幣別' },
        { key: 'payMethod', label: '繳費方式' },
        {
            key: 'insFee',
            label: '主附約總保費',
            isMoney: true
        },
        { key: 'notifyType', label: '通知類型' },
        { key: 'notifyContent', label: '通知內容' },
        { key: 'premiumStartDate', label: '墊繳開始日期' },
        { key: 'premiumEndDate', label: '墊繳結束日期' },
        { key: 'toInsureName', label: '要保人' },
        { key: 'fileDate', label: '保險公司製檔日', isTitle: true }
    ],
    // 客戶
    customerBySupplier: [
        { key: 'itemStatusChgDate', label: '異動日' },
        { key: 'id', label: '客戶ID' },
        { key: 'name', label: '客戶姓名', isTitle: true },
        { key: 'genderText', label: '客戶性別' },
        { key: 'birthday', label: '客戶生日' },
        { key: 'maritalStatus', label: '婚姻狀態' },
        { key: 'insuredOccupation', label: '客戶職業' },
        // { key: 'riskValue', label: '客戶風險值' },//如有保險公司回傳，和後端對過資料格式後再開放
        { key: 'mobile', label: '手機' },
        { key: 'contactPhone', label: '市內電話' },
        { key: 'residenceAddr', label: '戶籍地址', cell: 1 },
        { key: 'mailingAddr', label: '通訊地址', cell: 1 },
        { key: 'eMail', label: 'Email', cell: 1 },
        { key: 'fileDate', label: '保險公司製檔日' }
    ],
    // 基金
    fund: [
        { key: 'mainContractName', label: '主約名稱' },
        { key: 'insFeeType', label: '保費類型' }, // 3?
        { key: 'fundSupplier', label: '基金公司' },
        { key: 'fundAccountCode', label: '基金代號' },
        { key: 'fundAccountName', label: '基金名稱' },
        { key: 'accountType', label: '帳戶類別' },
        { key: 'fundRate', label: '基金分配比例' },
        { key: 'fundUnit', label: '基金單位數' },
        { key: 'fundCurrency', label: '基金幣別' },
        { key: 'exchangeRate', label: '計算時使用匯率' },
        { key: 'status', label: '狀態' },
        { key: 'calDate', label: '計算日期', isTitle: true }
    ]
}
//保單利益給付表之明細表欄位
const insuranceInterestList = [
    { key: 'benefitList', label: '保單利益給付表', cell: 1 },
    { key: 'insYear', label: '保單年度' },
    { key: 'insYearRemark', label: '備註', cell: 1 }
]

const syncDataNote = {
    forAll: ['顯示資訊為保險公司直接回傳的原始資料，如有問題請洽飛鴿'], //保險公司對接功能全部資訊備註
    forSome: {
        //key: 'string-為各別檔案裡的標題項目備註'
    }
}

export { config, syncOptions, syncDataNote, insuranceInterestList }
